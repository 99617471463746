<template>
  <div>
    <b-navbar
        variant="transparent"
        class="navbar navbar-shadow justify-content-between align-items-center"
        style="min-height: 4.45rem"
    >
      <b-nav class="mb-0 rounded" tabs>
        <b-nav-item
            v-for="(tab, index) in tabs"
            :key="index"
            :active="tab.active"
            @click="switchComponent(index)"
        >
          {{ $t(tab.label) }}
        </b-nav-item>
      </b-nav>
    </b-navbar>

    <KeepAlive>
      <transition name="zoom-fade" mode="out-in">
        <component :is="currentComponent"/>
      </transition>
    </KeepAlive>
  </div>
</template>

<script>
import articleOverviewTab from '@/@core/components/accountingData/article/articleOverview.vue';
import articleSegmentsTab from '@/@core/components/accountingData/article/articleSegments.vue';


export default {
  name: 'ArticlesView',
  components: {articleOverviewTab, articleSegmentsTab},
  data() {
    return {
      currentComponent: 'articleOverviewTab',
      tabs: [
        {
          label: 'articleCard.articleOverviewTab',
          active: true,
          component: 'articleOverviewTab',
        },
        {
          label: 'articleCard.articleSegmentsTab',
          active: false,
          component: 'articleSegmentsTab',
        },
      ],
    };
  },
  methods: {
    switchComponent(tabIndex) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[tabIndex].component;

      this.tabs[tabIndex].active = true;
    },
    openAccessDeniedModal() {
      this.$bvModal.show("modal-access-denied");
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
    },
  },

  mounted() {

    // window.Bus.$on('open-access-denied', e => {
    //  window.Bus.$emit('trigger-access-denied', e => {})
    // })
  },
};
</script>

<style></style>
