<template>
  <div>
    <div class="mt-2">
      <b-card>
        <!-- <Loading v-if="loadingState" /> -->
        <!-- <b-card-text v-if="hiddenTable"> -->
        <b-card-text>
          <b-row>
            <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center"
            >
              <p class="mb-0">
                <span
                    class=""
                    style="margin-right: 5px"
                    v-text="
                    $t(locale.product, {
                      productNumber: totalProducts,
                    })
                  "
                />
              </p>

              <b-button
                  v-ripple.400
                  @click="$router.push({ name: 'acountingCreateArticle' })"
                  variant="secondaryColor"
                  v-text="$t(locale.addArticle)"
              />
            </b-col>

            <b-col cols="12" class="mt-1">
              <b-row>
                <b-col cols="12" lg="5">
                  <div class="d-flex smallGap">
                    <b-button
                        v-for="(button, index) in categoryButton"
                        :key="index"
                        v-ripple.400
                        size="sm"
                        :class="
                        categoryValue === button.value ? 'custom-shadow' : ''
                      "
                        @click="selectedStatus(button.value)"
                        :variant="button.variant"
                        v-text="$t(button.label)"
                    />
                  </div>
                </b-col>
                <b-col
                    cols="12"
                    lg="7"
                    class="justify-content-end mt-1 mt-lg-0 d-flex"
                >
                  <div class="d-flex bigGap w-75 align-items-center">
                    <b-form-input
                        :placeholder="$t(locale.inputPlaceholder)"
                        v-model="queryParams.search"
                    />
                    <div
                        v-ripple.400
                        v-b-modal.modal-customize
                        class="d-flex text-secondaryColor smallGap"
                        role="button"
                    >
                      <img src="@/assets/icon/dashboardAdd.png" width="20px"/>
                      <p class="mb-0" v-text="$t(locale.customize)"/>
                    </div>
                  </div>
                </b-col>

                <!-- Table -->
                <!-- <b-col cols="12" class="mt-2 mb-2">
                                    <table class="table w-full z-50 relative mb-7">
                                        <thead class="">
                                            <tr>
                                                <th class="text-left whitespace-nowrap p-2 md:p-0">
                                                    <span class="text-xs text-gray-400">
                                                        <b-form-checkbox
                                                            
                                                        />
                                                    </span>
                                                </th>
                                                <th class="text-left whitespace-nowrap p-2 md:p-0" v-for="item in articleCustomization" :key="item"><span class="text-xs text-gray-400">{{ item }}</span></th>
                                                <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Action</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-col> -->

                <b-col cols="12" class="mt-2">
                  <div class="scrol-area table-responsive">
                    <div
                        v-if="loadingTable"
                        class="text-center text-danger my-2"
                    >
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('Message.loading') }}...</strong>
                    </div>
                    <table v-else class="table b-table table-hover">
                      <thead class="">
                      <tr>
                        <th class="text-left whitespace-nowrap p-2 md:p-0">
                            <span class="text-xs text-gray-400">
                              <b-form-checkbox
                                  v-model="selected"
                                  @change="performAllChecked"
                                  :value="checked"
                                  class="custom-control-primary my-auto"
                              />
                            </span>
                        </th>

                        <th
                            v-for="(item, i) in articleTableHeader"
                            :key="i"
                            class="text-center whitespace-nowrap p-2 md:p-0"
                        >
                          <!-- <span class="text-xs text-gray-400">{{ renderName(item ) }}</span> -->
                          <div
                              class="flex justify-between gap-5 items-center w-full"
                          >
                              <span class="text-xs text-gray-400">{{
                                  renderName(item)
                                }}</span>
                            <span class="flex flex-col">
                                <svg
                                    @click="sortColumn(item)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                                <svg
                                    @click="sortColumn(`-${item}`)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                          </div>
                        </th>
                        <th class="text-center whitespace-nowrap p-2 md:p-0">
                          <span class="text-xs text-gray-400">Action</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="data in items" :key="data.uuid">
                        <td>
                            <span>
                              <b-form-checkbox
                                  @change="performSingleChecked"
                                  v-model="data.checkbox"
                              />
                            </span>
                        </td>
                        <td v-for="(item, i) in articleTableHeader" :key="i">
                            <span
                                class="whitespace-nowrap text-sm"
                                v-if="['segment'].includes(item)"
                            >{{
                                data[item] && data[item].name
                                    ? data[item].name
                                    : "..."
                              }}</span
                            >
                          <span
                              class="whitespace-nowrap text-sm"
                              v-else-if="['tags'].includes(item)"
                          >{{ data[item].join(",") }}</span
                          >
                          <span
                              class="whitespace-nowrap text-sm"
                              v-else-if="hasInputField.includes(item)"
                          >
                              <b-form-input
                                  min="0"
                                  type="number"
                                  size="sm"
                                  @input="
                                  updateFieldValue($event, item, data.uuid)
                                "
                                  v-model="data[item]"
                              />
                            </span>
                          <router-link
                              :to="{
                                name: 'acountingEditArticle',
                                params: { id: data.uuid },
                              }"
                              v-else
                          >
                              <span
                                  class="whitespace-nowrap text-sm"
                                  v-if="['tags'].includes(item)"
                              >{{ data[item].join(",") }}</span
                              >
                            <span
                                class="whitespace-nowrap text-sm"
                                v-else-if="['active'].includes(item)"
                            >
                                <b-button
                                    v-ripple.400
                                    size="sm"
                                    :variant="data.active ? 'success' : 'warning'"
                                    v-text="
                                    data.active
                                      ? $t(categoryButton[1].label)
                                      : $t(categoryButton[2].label)
                                  "
                                />
                              </span>
                            <span
                                class="whitespace-nowrap text-sm"
                                v-else-if="['segment'].includes(item)"
                            >{{
                                data[item] && data[item].name
                                    ? data[item].name
                                    : "..."
                              }}</span
                            >

                            <span
                                v-else
                                class="whitespace-nowrap text-sm"
                                :class="{
                                  'text-green-500': data.active === true,
                                  'text-yellow-500': data.active === false,
                                }"
                            >{{ data[item] }}</span
                            >
                          </router-link>
                        </td>
                        <td>
                          <div
                              class="d-flex justify-content-between smallGap align-items-center px-2"
                          >
                              <span
                                  class="cursor-pointer"
                                  @click="openModalDelete(data)"
                              >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-secondaryColor"
                                    :title="'Delete Article'"
                                    name="close-circle-outline"
                                />
                              </span>

                            <span
                                @click="openActivityModal('ACTIVATE', data)"
                                class="cursor-pointer"
                                v-if="!data.active"
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    :title="'Activate Article'"
                                    class="text-secondaryColor"
                                    name="play"
                                />
                              </span>

                            <span
                                @click="openActivityModal('DEACTIVATE', data)"
                                class="cursor-pointer"
                                v-else
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-secondaryColor"
                                    :title="'Deactivate Article'"
                                    name="pause"
                                />
                              </span>
                            <span @click="openTags(data)">
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-secondaryColor"
                                    :name="
                                    data.tags.length > 0 ? 'tag' : 'tag-outline'
                                  "
                                />
                              </span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-row
                      class="justify-content-center justify-content-lg-between"
                  >
                    <b-col cols="12" sm="6" class="d-flex align-items-end">
                      <div
                          class="d-flex bigGap justify-content-between align-items-center"
                      >
                        <p class="mb-0 h6" v-text="$t(locale.action)"/>
                        <div class="">
                          <b-form-select
                              id="perPageSelect"
                              size="sm"
                              inline
                              v-model="actionLable"
                          >
                            <b-form-select-option
                                v-for="(option, index) in actionButton"
                                :key="index"
                                :value="option.value"
                                v-text="$t(option.label)"
                            />
                          </b-form-select>
                        </div>

                        <b-button
                            size="sm"
                            variant="primary"
                            :disabled="loadingAction"
                            @click="applyMultiAction(actionLable)"
                            v-text="
                            loadingAction
                              ? $t('Message.loading')
                              : $t(locale.apply)
                          "
                        />

                        <b-button
                            size="sm"
                            @click="openTags(null)"
                            variant="primary"
                            v-text="$t(locale.tag)"
                        />
                      </div>
                    </b-col>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
                    >
                      <!-- <b-pagination
                                                v-model="pagination.currentPage"
                                                :total-rows="
                                                    pagination.totalProducts
                                                "
                                                :per-page="pagination.perPage"
                                                first-number
                                                last-number
                                                class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                                                prev-class="prev-item"
                                                next-class="next-item"
                                            >
                                                <template #prev-text>
                                                    <feather-icon
                                                        icon="ChevronLeftIcon"
                                                        size="18"
                                                    />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon
                                                        icon="ChevronRightIcon"
                                                        size="18"
                                                    />
                                                </template>
                                            </b-pagination> -->
                      <paginate
                          v-model="pagination.currentPage"
                          :page-count="pagination.totalProducts"
                          :click-handler="firePagination"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :page-class="'page-item c-mr-1 border-unset'"
                          prev-link-class="page-link c-mr-1 border-unset"
                          next-link-class="page-link c-mr-1 border-unset"
                          next-class="next-item"
                          prev-class="prev-item"
                          page-link-class="page-link c-mr-1 border-unset"
                          active-class="active c-mr-1 border-unset"
                          :container-class="'pagination  b-pagination'"
                      >
                      </paginate>
                      <div
                          class="d-flex justify-content-between justify-content-sm-end smallGap align-items-center mt-1 w-100 order-1 order-sm-2"
                      >
                        <p
                            class="mb-0 d-none d-sm-block h6"
                            v-text="$t(locale.showPerPage)"
                        />
                        <div class="">
                          <b-form-select
                              v-model="queryParams.limit"
                              id="perPageSelect"
                              size="sm"
                              @change="switchLimit"
                              inline
                              :options="pageOptions"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </div>

    <Modal
        id="modal-tagContract"
        title="Tag Selection"
        :closeEvent="closeModal"
        :hideFooter="true"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
            }"
            :okEvent="okEvent" -->
      <div class="scrol-area" style="height: 200px">
        <div class="grid grid-cols-2 md:grid-cols-3 gap-3">
          <div v-for="data in tagResults" :key="data.uuid">
            <div class="d-flex custom-border bg-white rounded-md c-p-2">
              <b-form-checkbox
                  :value="data.name"
                  v-model="selectedTags"
                  class="text-white"
              >
                {{ data.name }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex smallgap pt-1 justify-content-between">
        <div>
          <b-form-input
              v-model="tagData"
              size="sm"
              :placeholder="$t(locale.addTag)"
          />
        </div>

        <div>
          <b-button
              size="sm"
              @click="saveTag"
              variant="primary"
              v-text="savingTag ? $t('Message.loading') : $t(locale.save)"
          />
        </div>
      </div>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-tagContract')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="assignTagData"
        >
          <span
              v-text="
              sendingModalAction ? $t('Message.loading') : $t(locale.save)
            "
          />
        </b-button>
      </div>
    </Modal>

    <Modal
        id="modal-customize"
        :title="
        $t(locale.modalCustomize, {
          pageTitle: $t($route.meta.pageTitle),
        })
      "
        :hideFooter="true"
    >
      <!-- :options="{
                size: 'lg',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
                'ok-title': this.$t(locale.update),
            }" -->
      <div class="scrol-area" style="height: 250px">
        <draggable v-model="articleTableHeader">
          <b-card
              v-for="(data, index) in articleTableHeader"
              :key="index"
              no-body
              class="border mb-1"
              style="cursor: grab"
          >
            <b-card-text class="p-1 d-flex bigGap">
              <mdicon :width="20" :height="20" name="flip-to-back"/>

              <p class="mb-0" v-text="renderName(data)"/>
            </b-card-text>
          </b-card>
        </draggable>
      </div>

      <div class="pt-1">
        <p class="" v-text="$t(locale.chooseColumn)"/>
        <b-row class="scrol-area">
          <b-col
              cols="12"
              lg="6"
              v-for="(column, index) in Object.keys(ARTICLE_CUSTOMIZE)"
              :key="index"
          >
            <div class="mt-1">
              <b-form-checkbox v-model="articleTableHeader" :value="column">
                {{ renderName(column) }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex smallgap pt-1 justify-content-end">
        <div>
          <b-button
              variant="primary"
              @click="runCustomization"
              v-text="
              sendingModalAction ? $t('Message.loading') : $t(locale.update)
            "
          />
        </div>
      </div>
    </Modal>

    <Modal
        id="modal-multi-action"
        :hideFooter="true"
        :title="choosenData.mainTitle"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ choosenData.banner }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-multi-action')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="performModalAction"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') : $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>
    <Modal
        v-if="accessDenied"
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import Paginate from "vuejs-paginate";
import {
  ARTICLE_CUSTOMIZATION,
  renderCustomizeName,
} from "./../customization/Constants";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import debounce from "lodash.debounce";
import accessDenied from "@core/components/permission/accessDenied";
import {mapGetters} from "vuex";

export default {
  name: "articleOverview",
  components: {
    paginate: Paginate, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      search: "",
      accessDenied: true,
      totalProducts: "",
      loadingState: true,
      hiddenTable: false,
      activeLabel: "all",
      loadingTable: false,
      sendingModalAction: false,
      ARTICLE_CUSTOMIZE: {...ARTICLE_CUSTOMIZATION},
      savingTag: false,
      selectedTags: [],
      categoryValue: "all",
      articleCustomization: [],
      items: [],
      queryParams: {
        active: "all",
        limit: 50,
      },
      pageOptions: [50, 100, 150, 200],
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      actionLable: "",
      loadingAction: false,
      selected: false,
      checked: "selected",
      selectedData: {},
      choosenData: {},
      hasInputField: ["sales_price", "purchase_price", "quantity_in_stock"],
      actionButton: [
        {
          label: "CustomersCard.enabled",
          variant: "success",
          value: "True",
        },
        {
          label: "CustomersCard.inactive",
          variant: "warning",
          value: "False",
        },
      ],
      categoryButton: [
        {
          label: "CustomersCard.all",
          variant: "primary",
          value: "all",
        },
        {
          label: "CustomersCard.enabled",
          variant: "success",
          value: true,
        },
        {
          label: "CustomersCard.inactive",
          variant: "warning",
          value: false,
        },
      ],

      fields: [
        {
          key: "checkbox",
          tdClass: "bTableMainThStyle",
          thStyle: {width: "20px", padding: "0.72rem 10px"},
        },
        {
          key: "article_number",
          label: "articleCard.table.articleNumber",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "140px",
          },
        },
        {
          key: "supplier_name",
          label: "CustomersCard.table.name",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
        {
          key: "sales_price",
          label: "articleCard.table.sales_price",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "250px",
          },
        },
        {
          key: "purchase_price",
          label: "articleCard.table.cost",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "quantity_in_stock",
          label: "articleCard.table.inStock",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "reserved_quantity",
          label: "articleCard.table.reserved",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
        {
          key: "disposable_quantity",
          label: "articleCard.table.available",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },

        {
          key: "action",
          label: "CustomersCard.table.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
      ],

      locale: {
        product: "articleCard.product",
        addArticle: "articleCard.addArticle",
        customize: "CustomersCard.customize",
        inputPlaceholder: "articleCard.seachLabel",
        showPerPage: "golfRelatedCard.showPerPage",
        tag: "CustomersCard.tag",
        apply: "golfRelatedCard.apply",
        action: "golfRelatedCard.action",
        modalCustomize: "customizeColumn",
        update: "UsersCard.update",

        tagContract: "contractsCard.tagContract",
        save: "save",
        addTag: "contractsCard.addTag",
      },
      tagData: "",
      tagResults: [],
      articleTableHeader: [],
    };
  },

  watch: {
    "queryParams.search": {
      handler: debounce(function (value) {
        this.queryParams.search = value;
        this.getArticles(false);
      },1500),
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "swe_message" : "eng_message";
    },
    features() {
      return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    switchLimit(value) {
      this.queryParams.limit = value;
      this.getArticles();
    },

    selectedRow(value) {
      this.$router.push({
        name: "acountingEditArticle",
        params: {id: value.uuid},
      });
    },

    selectedStatus(value) {
      this.categoryValue = value;
      if (this.search === "") {
        delete this.queryParams.search;
      }
      this.queryParams.active = value;

      this.getArticles(false);
    },
    performAllChecked(e) {
      this.items.forEach((element) => {
        element.checkbox = this.selected === "selected" ? true : false;
      });
    },
    performSingleChecked(e) {
      // left blank
    },
    renderName(key) {
      return renderCustomizeName(this.getLang, key, ARTICLE_CUSTOMIZATION);
    },
    saveTag() {
      if (this.tagData !== "") {
        this.savingTag = true;
        const payload = {
          type: "ARTICLE",
          name: this.tagData,
        };
        this.$useJwt
            .customTags({URL: "/", method: "post"}, payload)
            .then((res) => {
              this.savingTag = false;
              this.getTags();
              this.tagData = "";
            })
            .catch((err) => {
              this.savingTag = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    getTags() {
      this.$useJwt
          .customTags({URL: "?type=ARTICLE", method: "get"})
          .then((res) => {
            this.tagResults = res.data.results;
          });
    },
    firePagination(number) {
      this.pagination.currentPage = number;
      this.queryParams.page = number;
      this.getArticles(true);
    },
    runCustomization() {
      if (this.articleTableHeader.length > 0) {
        this.sendingModalAction = true;
        const payload = {
          columns: this.articleTableHeader,
        };
        this.$useJwt
            .customization({URL: "article", method: "put"}, payload)
            .then((res) => {
              this.getCustomizeHeader();
              this.sendingModalAction = false;
              this.getArticles(false);
              this.$bvModal.hide("modal-customize");
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data.message,
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    getCustomizeHeader() {
      this.$useJwt
          .customization({URL: "article", method: "get"})
          .then((res) => {
            const fields = [
              {
                key: "checkbox",
                label: "articleCard.table.articleNumber",
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "50px",
                },
              },
              {
                key: "action",
                label: "CustomersCard.table.action",
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "100px",
                },
              },
            ];
            if (res.data.data.length > 0) {
              this.articleTableHeader = res.data.data;
              // res.data.data.map((el, i) => {
              //     const obj = {
              //         key: el,
              //         label: el,
              //         tdClass: 'bTableThStyle',
              //         thStyle: {
              //             padding: '0.72rem 10px',
              //             width: '140px',
              //         },
              //     }
              //     fields.splice(parseInt(i + 1), 0, obj)
              // })
            } else {
              Object.keys(ARTICLE_CUSTOMIZATION).forEach((el) => {
                this.articleTableHeader.push(el);
              });
            }
            // this.fields = fields
          });
    },
    getArticles(afterLoad) {
      this.loadingTable = true;
      this.$useJwt.getArticles({params: this.queryParams}).then((res) => {
        const {data} = res.data;

        this.items = data.map((el) => {
          return {
            ...el,
            checkbox: false,
          };
        });

        if (!afterLoad) {
          this.loadingState = false;
          this.hiddenTable = true;
        }

        this.loadingTable = false;

        this.pagination = {
          totalProducts: res.data.total_pages,
          // perPage: res.data.limit,
        };
      }).catch(err => {
        this.loadingTable = false;
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      });
    },
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    assignTagData() {
      const payload = {
        tags: this.selectedTags,
        selected: [],
      };
      const filter = this.items.filter((el) => el.checkbox);
      if (filter.length > 0) {
        this.sendingModalAction = true;
        filter.map((el) => {
          payload.selected.push(el.uuid);
        });

        this.$useJwt
            .customArticles({URL: "/bulktag", method: "put"}, payload)
            .then((res) => {
              this.selectedTags = [];
              this.sendingModalAction = false;
              this.$bvModal.hide("modal-tagContract");
              this.getArticles(false);
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.message],
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    applyMultiAction(value) {
      if (value) {
        const payload = {
          selected: [],
          active: value,
        };
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          this.loadingAction = true;
          filter.map((el) => {
            payload.selected.push(el.uuid);
          });

          this.$useJwt
              .customArticles({URL: "/status", method: "put"}, payload)
              .then((res) => {
                this.actionLable = "";
                this.loadingAction = false;
                this.getArticles(false);
                // this.getContracts({ URL : '', method : 'get' });
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data.message,
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.loadingAction = false;
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data[this.message],
                    "AlertTriangleIcon",
                    "danger"
                );
              });
        } else {
          this.popupMsg(
              this.$t("Message.Failed"),
              "Select articles to apply action",
              "AlertTriangleIcon",
              "danger"
          );
        }
      }
    },
    openActivityModal(mode, data) {
      this.choosenData = data;
      this.choosenData["banner"] =
          mode === "ACTIVATE"
              ? "Är du säker på att du vill aktivera denna artikel?"
              : "Är du säker på att du vill inaktivera denna artikel?";
      this.choosenData["mainTitle"] =
          mode === "ACTIVATE" ? "Aktivera artikel" : "Inaktivera artikel";
      this.choosenData["mode"] = mode;
      this.$bvModal.show("modal-multi-action");
    },
    openModalDelete(item) {
      this.choosenData = item;
      this.choosenData["banner"] =
          "Är du säker på att du vill radera denna artikel";
      this.choosenData["mainTitle"] = "Radera artikel";
      this.choosenData["mode"] = "DELETE";
      this.$bvModal.show("modal-multi-action");
    },
    performModalAction() {
      this.sendingModalAction = true;
      if (this.choosenData.mode === "ACTIVATE") {
        this.toggleActivity(true, this.choosenData, false);
      } else if (this.choosenData.mode === "DEACTIVATE") {
        this.toggleActivity(false, this.choosenData, false);
      } else {
        this.toggleActivity(false, this.choosenData, true);
      }
      this.sendingModalAction = false;
      this.$bvModal.hide("modal-multi-action");
    },
    sortColumn(item) {
      this.queryParams.ordering = item;
      delete this.queryParams.search;
      this.getArticles();
    },
    openTags(item = null) {
      if (item) {
        item.checkbox = true;
        this.selectedTags = item.tags;
        this.$bvModal.show("modal-tagContract");
      } else {
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          this.$bvModal.show("modal-tagContract");
        }
      }
    },
    toggleActivity(value, data, remove = false) {
      let urlMode = {URL: "", method: "put"};
      let payload = {
        active: value,
      };

      if (remove) {
        urlMode = {URL: `/${data.uuid}`, method: "delete"};
        payload = {};
      } else {
        urlMode = {URL: `/${data.uuid}`, method: "put"};
      }

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          // text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });

      this.$useJwt
          .customArticles(urlMode, payload)
          .then((res) => {
            this.getArticles();
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.loadingAction = false;
            if (err.response.data.message) {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data.message,
                  "AlertTriangleIcon",
                  "danger"
              );
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    updateFieldValue: debounce(function (event, item, uuid) {
      if (event) {
        this.updateField(event, item, uuid);
      }
    }, 350),
    updateField(value, field, uuid) {
      const payload = {};
      payload[field] = value;

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          // text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });

      this.$useJwt
          .customArticles({URL: `/${uuid}`, method: "put"}, payload)
          .then((res) => {
            this.getArticles();
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            if (err.response.data.message) {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data.message,
                  "AlertTriangleIcon",
                  "danger"
              );
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    closeModal() {
    },
  },

  mounted() {
    this.getCustomizeHeader();
    this.getArticles();
    this.getTags();
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)

  },
};
</script>

import { debounce } from "lodash";
<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.scrol-area {
  overflow: auto;
  scrollbar-color: $primary transparent;
}

.table th {
  padding: 0.4rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  padding: 0.4rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
